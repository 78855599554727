import { GrapeAppAuthorization } from "@grape-law-firm/grape-common-notion-client";
import type { GNavItem } from "~/types/navigation";

export function useNavigation() {
  const navItems: GNavItem[] = [
    {
      label: "Timesheets",
      icon: "i-heroicons-clock",
      to: "/timesheets",
      requiredAuthorizations: [GrapeAppAuthorization.Timesheet],
    },
    {
      label: "Translator",
      icon: "i-heroicons-language",
      to: "/translator",
      requiredAuthorizations: [GrapeAppAuthorization.Assistant],
    },
    {
      label: "Missive Helper",
      icon: "i-heroicons-envelope",
      to: "/missive",
      requiredAuthorizations: [GrapeAppAuthorization.MissiveHelper],
    },
    {
      label: "Mailchimp Sync",
      icon: "i-heroicons-arrow-path",
      to: "/mailchimp",
      requiredAuthorizations: [GrapeAppAuthorization.SyncHubspotLists],
    },
    {
      label: "Search",
      icon: "i-heroicons-magnifying-glass",
      to: "/search",
      requiredAuthorizations: [GrapeAppAuthorization.Search],
    },
    {
      label: "Leaves",
      icon: "i-heroicons-calendar",
      to: "/leaves",
      requiredAuthorizations: [GrapeAppAuthorization.Leaves],
    },
    {
      label: "Visa Bulletin",
      icon: "i-heroicons-document-plus",
      to: "/bulletin",
      requiredAuthorizations: [GrapeAppAuthorization.VisaBulletin],
    },
  ];

  const missiveNavItems = [...navItems];

  const currentNavItem = computed(() => {
    const route = useRoute();
    return navItems.find(navItem => navItem.to === route.path);
  });

  return { navItems, missiveNavItems, currentNavItem };
}
